import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ip, uploadingApi } from "../../config";
import { GlobalContext } from "../../contexts/GlobalContext";
import AlertDialog from "../MessageBox/message";
import CustomModal from "../CustomModal.js/CustomMessageModal";
import CustomSnackBar from "../CustomModal.js/SnackBarForBackEndErrors";
const MainDashboardContent = () => {
  const { token, setToken } = useContext(GlobalContext);
  const [limitReached, setLimitReached] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${ip}/initialStats`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.processed > 502) {
          setLimitReached(true);
          setOpenModal(true);
        }

        setprocessed(response.data.processed);

        setpending(response.data.pendingCalls);
        settotalcalls(response.data.totalCalls);
        settotalmins(response.data.mins);
      } catch (e) {}
    };
    const check = async () => {
      if (!token) {
        navigate("/login");
      }
      try {
        const response = await axios.get(`${ip}/validate`, {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in the Authorization header
          },
        });
        getData();
      } catch (e) {
        localStorage.removeItem("loginToken");
        setToken(null);
        navigate("/login");
        return 0;
      }
    };

    check();
  }, []);
  const [totalcalls, settotalcalls] = useState("...");
  const [pending, setpending] = useState("...");
  const [processed, setprocessed] = useState("...");
  const [totalmins, settotalmins] = useState("...");

  const [files, setFiles] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [error, seterror] = useState(
    "Some files are not allowed please try selecting files with .tar.wav extension as shared earlier."
  );
  const [errorTitle, setErrorTile] = useState("Invalid File");
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [snackmessage,setsnackmessage] = useState('')
  const [openSnackBar,setOpenSnackBar] = useState(false)
  const StartUpload = async () => {
    if (files.length < 1) {
      setsnackmessage("No Files To Upload")
      setOpenSnackBar(true)
      return;
    }

    const validFiles = files.filter(
      (file) => file.name.endsWith(".tar.wav") || file.name.endsWith(".bz2")
    );
    const invalidFiles = files.filter(
      (file) => !file.name.endsWith(".tar.wav") && !file.name.endsWith(".bz2")
    );
    if (invalidFiles.length > 0) {
      setShow(true);
      seterror(
        "Some files are not allowed. Please select files with .tar.wav or .bz2 extension."
      );
      setErrorTile("Wrong File Selected");
      setFiles(validFiles); 
      return; // Exit if there are invalid files
    }

    setVisible(true);
    let uploadedCount = 0;
    const successfullyUploadedFiles = [];
    const failedFiles = [];

    for (let i = 0; i < validFiles.length; i++) {
      const formData = new FormData();
      formData.append("files", validFiles[i]);

      try {
        await axios.post(`${uploadingApi}/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        uploadedCount += 1;
        successfullyUploadedFiles.push(validFiles[i]);
        setCompleted(Math.floor((uploadedCount / validFiles.length) * 100));

        await new Promise((resolve) => setTimeout(resolve, 1000));
      } catch (error) {
        // Track failed files
        failedFiles.push(validFiles[i]);
       
        if (error.status === 409) {
          // setErrorTile("File Already Uploaded");
          // seterror(`This file is already uploaded: ${validFiles[i].name}`);
          setsnackmessage(`This File aready been uploaded: ${validFiles[i].name}`)
          setOpenSnackBar(true)
        } else if (error.status === 402) {
          // setErrorTile("Limit Exceeded");
          // seterror("");
          setsnackmessage(`You have reached the max quota of 500 uploads.`)
          setOpenSnackBar(true)
          break; 
        } 
        else if (error.status === 403) {
          localStorage.removeItem("loginToken");
          setToken(null);
          navigate("/login");
          break; 
        } else {
          // setErrorTile("Network Error");
          // seterror(`Network Error: Failed to upload ${validFiles[i].name}`);
          setsnackmessage(`Network Error`)
          setOpenSnackBar(true)
        }
      }
    }

    // Update file lists and show remaining files or success message
    const remainingFiles = validFiles.filter(
      (file) => !successfullyUploadedFiles.includes(file)
    );

    let combinedMessage = "";

    if (successfullyUploadedFiles.length > 0) {
      combinedMessage += `Successfully uploaded files: ${successfullyUploadedFiles
        .map((file) => file.name)
        .join(", ")}.\n`;
    }

    if (remainingFiles.length > 0) {
      combinedMessage += `Failed to upload(Dublicates/Not Allowed): ${remainingFiles
        .map((file) => file.name)
        .join(", ")}.`;
    }

    if (combinedMessage) {
     
      setErrorTile("Upload Summary");
      seterror(combinedMessage);
    }

    setFiles(remainingFiles);
    setVisible(false);
    setShow(true);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter((file) =>
      file.name.endsWith(".bz2")
    );
    const invalidFiles = selectedFiles.filter(
      (file) => !file.name.endsWith(".bz2")
    );
    if (invalidFiles.length > 0) {
      setErrorTile("Invalid files");
      seterror(
        "Files with .wav.tar are accepted only! Removed uncompatiable files!"
      );
      setShow(true);
    }
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  return (
    <>
      <AlertDialog
        open={show}
        setOpen={setShow}
        title={errorTitle}
        message={error}
      />
      <CustomSnackBar 
        open={openSnackBar} 
        messageBackEnd={snackmessage} 
        onClose={()=>setOpenSnackBar(false)}
      />
      <CustomModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        heading="Quota Limit Reached"
        message="Your maximum quota for 500 calls has been completed! Uploading might not work! Please contact the system administrator!"
      />
      <Modal open={visible}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: { xs: "90%", sm: "25%" },
            maxWidth: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            borderRadius: 3,
            p: 2,
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3
            style={{
              margin: 0,
              fontSize: { xs: "16px", sm: "18px" },
              textAlign: "center",
            }}
          >
            Hold on uploading files
          </h3>
          <CircularProgress
            size={70}
            sx={{
              margin: "20px 0",
            }}
            variant="determinate"
            value={completed}
          />
          <Typography
            variant="caption"
            component="div"
            sx={{
              color: "black",
              fontWeight: "bold",
              fontSize: { xs: "16px", sm: "20px" },
            }}
          >
            {`${Math.round(completed)}%`}{" "}
          </Typography>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center-aligns the content horizontally
          width: { xs: "90%", md: "80%" },
          mx: "auto",
          mt: 4,
          color: "white",
          textAlign: "center", // Centers text inside the Box
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          Welcome Nayatel's Admin
        </Typography>
        <Typography variant="p" sx={{ fontWeight: "semiBold", mb: 5 }}>
          (Current model is optimized for handling query and complaint calls,
          while billing and sales calls may have lower accuracy, dedicated
          models with customized metrics will be developed for those, for
          enhanced accuracy)
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 3,
            mb: 4,
            flexWrap: "wrap",
            alignSelf: "center",
          }}
        >
          {[
            "Total Uploaded",
            "Processed",
            "Total (Mins) Processed",
            "In Queue",
          ].map((label) => (
            <Card
              key={label}
              sx={{
                minWidth: 330,
                flexGrow: 1,
                borderRadius: 2,
                border: "1px solid #ddd",
                boxShadow: 3,
                position: "relative",
                overflow: "hidden",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: 6,
                },
              }}
            >
              {/* Liquid Effect Background */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "linear-gradient(135deg, #1a2530, #1f3545)",
                  animation: "liquidEffect 2s infinite alternate",
                  zIndex: 0,
                }}
              />
              {/* Card Content */}
              <CardContent
                sx={{ textAlign: "center", position: "relative", zIndex: 1 }}
              >
                <Typography variant="h6" sx={{ color: "white", mb: 1 }}>
                  {label}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", color: "primary.main", mt: 2 }}
                >
                  {label == "Total Uploaded"
                    ? totalcalls
                    : label == "Processed"
                    ? processed
                    : label == "Total (Mins) Processed"
                    ? totalmins
                    : label == "In Queue"
                    ? pending
                    : null}
                </Typography>
              </CardContent>
              <style jsx>{`
                @keyframes liquidEffect {
                  0% {
                    background: linear-gradient(135deg, #1a2530, #1f3545);
                  }
                  20% {
                    background: linear-gradient(135deg, #1f3545, #1a2530);
                  }
                  100% {
                    background: linear-gradient(135deg, #1a2530, #1f3545);
                  }
                }
              `}</style>
            </Card>
          ))}
        </Box>

        <input
          type="file"
          hidden
          multiple
          onChange={handleFileChange}
          accept=".bz2"
          ref={fileInputRef}
        />
        {!limitReached && (
          <>
            {files.length === 0 ? (
              <Box
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => {
                  fileInputRef.current.click();
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  border: "1px dashed #ddd",
                  borderRadius: 2,
                  p: 4,
                  width: { xs: "80%", sm: "60%", md: "30%" },
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                  backgroundColor: "#2a3b4d",
                  "&:hover": {
                    backgroundColor: "#3a4b5d",
                  },
                  mx: "auto",
                }}
              >
                <label htmlFor="file-upload" style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      width: "100%",
                    }}
                  >
                    <CloudUploadIcon fontSize="large" sx={{ color: "white" }} />
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "white" }}
                    >
                      Upload Files
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, fontWeight: "light", color: "white" }}
                  >
                    Drag and drop your audio file here or click to upload
                  </Typography>
                </label>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => fileInputRef.current.click()}
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: "#2a3b4d",
                    "&:hover": { backgroundColor: "#3a4b5d" },
                  }}
                >
                  <AddCircleIcon fontSize="large" sx={{ color: "white" }} />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 1, color: "white" }}>
                  Upload More Files
                </Typography>
              </Box>
            )}

            {files.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  onClick={StartUpload}
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#007BFF",
                    color: "white",
                    padding: "8px 16px",
                    borderRadius: 10,
                    "&:hover": {
                      backgroundColor: "#0056b3",
                    },
                    width: "400px",
                    height: "70px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                  }}
                  endIcon={<ArrowForwardIcon />}
                >
                  Proceed Now
                </Button>
              </Box>
            )}
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 4,
            width: "100%",
          }}
        >
          {files.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mb: 2, color: "white" }}>
                Uploaded Files:
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  backgroundColor: "#1a2530",
                  padding: 2,
                  borderRadius: 2,
                }}
              >
                {files.map((file, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        borderRadius: 2,
                        padding: 2,
                        backgroundColor: "#2a3b4d",
                        boxShadow: 1,
                        transition: "box-shadow 0.2s",
                        "&:hover": {
                          boxShadow: 3,
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{ flexGrow: 1, color: "white" }}
                      >
                        {file.name}
                      </Typography>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveFile(index)}
                      >
                        <DeleteIcon sx={{ color: "white" }} />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MainDashboardContent;
