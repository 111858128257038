import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Drawer,
  CircularProgress,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AudioPlayer from "material-ui-audio-player";
import { menuItems, audioDummyData } from "../../DummyData/ViewDetailsData";
import ListIcon from "@mui/icons-material/List";
import GaugeChart from "react-gauge-chart";
import { ip, audioSource } from "../../config";
import axios from "axios";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { metrics } from "../../DummyData/TableData";
import { parseSRT } from "./SrtFunction";
const ViewDetailsContent = ({ audioId }) => {
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { token, setToken } = useContext(GlobalContext);
  const [fetchaudioDetails, setAudioDetails] = useState([]);
  const [transcribtionData, setTranscribtionData] = useState("");
  const [points, setpoints] = useState(false);
  const [evaluation, setevaluation] = useState({});
  const navigate = useNavigate();
  const [audioSrc, setAudioSrc] = useState("");
  const [Audname, setAudName] = useState("");
  const [subtitles, setSubtitles] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [transcriptionType, setTranscriptionType] = useState("");
  const [newsub, setnewsub] = useState([]);
  const audioRef = useRef(null);
  const totalMaxScore = 85;
  const [holdflow, setholdflow] = useState(false);
  const [error, setError] = useState(false);
  const totalObtainedMarks = useMemo(() => {
    return Object.values(evaluation).reduce(
      (acc, item) => acc + (Number(item.obtained_points) || 0),
      0
    );
  }, [evaluation]);

  const overallPercentage = useMemo(() => {
    return ((totalObtainedMarks / totalMaxScore) * 100).toFixed(1);
  }, [totalObtainedMarks, totalMaxScore]);

  const srtString = `1
  00:00:01,000 --> 00:00:05,000
  Hello, welcome to the audio!
  
  2
  00:00:05,000 --> 00:00:10,000
  This is a sample subtitle text.`;
  const handleTimeUpdate = () => {
    //console.log("sub", newsub);
    const currentTime = audioRef.current.currentTime * 1000;
    const current = newsub.find(
      (sub) => currentTime >= sub.start && currentTime <= sub.end
    );
    setCurrentSubtitle(current ? current.text : "");
    //console.log("Current", current);

    setCurrentSubtitle(current ? current.text : "");
    //console.log("currnent:s", currentSubtitle);
  };
  //Use Effect To fetch Audio Souce Updated
  useEffect(() => {
    const fetchAudio = async () => {
      try {
        const response = await axios.get(`${audioSource}/${Audname}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "audio/wav",
          },
          responseType: "blob", // Important for handling binary data
        });

        const url = URL.createObjectURL(response.data);
        //console.log("url", url);
        setAudioSrc(url);
      } catch (error) {
        //console.error("Error fetching audio:", error);
      }
    };

    fetchAudio();

    // Cleanup blob URL on component unmount
    return () => {
      if (audioSrc) {
        URL.revokeObjectURL(audioSrc);
      }
    };
  }, [Audname]);
  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };
  useEffect(() => {
    const getAudioDetails = async () => {
      try {
        const response = await axios.get(`${ip}/audioDetails/` + audioId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setAudioDetails(response.data);
        setAudName(response.data.name);
        const parsedSubtitles = parseSRT(
          response?.data?.details?.AudioMetaData
            ?.UrduTranscriptionWithTimeStamps
        );
        if (parsedSubtitles.length > 0) {
          setSubtitles(parsedSubtitles);
          //console.log("datnewa", subtitles);
        }
      } catch (e) {
        //console.error("Failed to get audio details", e);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    const check = async () => {
      if (!token) {
        navigate("/login");
        return;
      }
      try {
        await axios.get(`${ip}/validate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        getAudioDetails();
      } catch (e) {
        localStorage.removeItem("loginToken");
        setToken(null);
        navigate("/login");
      }
    };

    check();
  }, []);

  useEffect(() => {
    if (subtitles.length > 0) {
      // Handle any actions that depend on the updated subtitles
      //console.log("Updated subtitles:", subtitles);
      setnewsub(subtitles);
    }
  }, [subtitles]);

  useEffect(() => {
    if (fetchaudioDetails?.details?.CommonEvaluations) {
      try {
        const evaluations = JSON.parse(
          fetchaudioDetails.details.CommonEvaluations
        );
        if (evaluations.proper_probing?.justification) {
          const pointsMatch = evaluations.proper_probing.justification.match(
            /obtained_points: (\d+)/
          );
          if (pointsMatch) {
            evaluations.proper_probing.obtained_points = pointsMatch[1];
            evaluations.proper_probing.justification =
              evaluations.proper_probing.justification
                .replace(/obtained_points: \d+/, "")
                .trim(); // Remove the points from justification
          }
        }
        setevaluation(evaluations);
        //console.log("evaluations", evaluations);
      } catch (e) {
        //console.error("Failed to parse CommonEvaluations", e);
      }
    }
  }, [fetchaudioDetails]);
  if (error) {
    return (
      <Container
        maxWidth={false}
        sx={{
          padding: 2,
          backgroundColor: "#101b23",
          transition: "background-color 0.5s ease",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 64, color: "white" }} />
          <Typography variant="h6" sx={{ color: "white", marginTop: 2 }}>
            Failed to load details
          </Typography>
        </Box>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container
        maxWidth={false}
        sx={{
          padding: 2,
          backgroundColor: "#101b23",
          transition: "background-color 0.5s ease",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress size="60px" sx={{ color: "white" }} />
        </Box>
      </Container>
    );
  }

  if (!loading) {
    const sidebarContent = (
      <Box
        sx={{
          width: 250,

          color: "#fff",
          padding: 2,
          height: "100%",
          borderRadius: "10px",
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Transcripts
        </Typography>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              sx={{
                marginBottom: 1,
                "&:hover": {
                  backgroundColor: "#3b4d65",
                  color: "#fff",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setTranscriptionType(item.text); // Add this line to store the selected transcript type
                if (item.text === "English Transcript") {
                  setTranscribtionData(
                    fetchaudioDetails.details.AudioMetaData.EnglishTranscription
                  );
                  setpoints(false);
                  setholdflow(false);
                } else if (item.text === "Urdu Transcript") {
                  setTranscribtionData(
                    fetchaudioDetails.details.AudioMetaData.Urdutranscription
                  );
                  setpoints(false);
                  setholdflow(false);
                } else if (item.text === "Short Summary") {
                  setTranscribtionData(fetchaudioDetails.details.SummaryShort);
                  setpoints(false);
                  setholdflow(false);
                } else if (item.text === "Detailed Summary") {
                  setTranscribtionData(fetchaudioDetails.details.Summary);
                  setpoints(false);
                } else if (item.text === "Troubleshoot Steps") {
                  setTranscribtionData(
                    fetchaudioDetails?.details?.TroubleshootSteps
                  );
                  setpoints(false);
                  setholdflow(false);
                } else if (item.text === "Detailed Analysis") {
                  setTranscribtionData(
                    fetchaudioDetails.details.CommonEvaluations
                  );
                  setpoints(true);
                  setholdflow(false);
                } else if (item.text === "Hold Flow") {
                  console.log(
                    "Hold FLow Marks",
                    fetchaudioDetails.details.AudioMetaData
                      .LongerSilentSpotsDuration
                  );
                  setTranscribtionData(
                    fetchaudioDetails.details.AudioMetaData
                      .LongerSilentSpotsDuration
                  );
                  setpoints(false);
                  setholdflow(true);
                }
              }}
            >
              <ListItemIcon sx={{ color: "#fff" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    );

    return (
      <Container
        maxWidth={false}
        sx={{
          padding: 2,
          backgroundColor: "#101b23",
          transition: "background-color 0.5s ease",
          minHeight: "100vh",
        }}
      >
        <Box>
          {!isDrawerOpen && (
            <IconButton
              edge="start"
              sx={{
                display: { sm: "none" },
                position: "fixed",
                top: 36,
                left: 10,
                zIndex: 1300,
                color: "white",
                overflowX: "hidden",
              }}
              onClick={toggleDrawer}
            >
              <ListIcon /> Transcribe Now
            </IconButton>
          )}
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer}
            sx={{
              "& .MuiDrawer-paper": {
                backgroundColor: "#182934",
                width: 250,
                overflow: "hidden",
              },
            }}
          >
            {sidebarContent}
          </Drawer>

          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              position: "fixed",
              top: 100,
              left: 0,
              minHeight: "60vh",
              maxHeight: "100vh",
              boxShadow: "2px 0px 5px rgba(0,0,0,0.5)",
              backgroundColor: "#182934",
              width: 300,
              borderRadius: "10px",
              overflowY: "hidden",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            {sidebarContent}
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            padding: { xs: 2, sm: "2rem 2rem 2rem 260px" },
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "612px",
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "615px",
                    mt: 1,
                    padding: 1,

                    borderRadius: 1,
                    position: "relative",
                    marginBottom: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginLeft: -1,
                    marginTop: 6,
                    overflow: "hidden",
                  }}
                >
                  {/* <Typography
                    variant="h6" // Heading style
                    sx={{
                      color: "#fff", // White text color for the heading
                      fontWeight: "bold",
                      padding: 1,
                      textAlign: "center",
                      fontFamily: "Jameel Noori",
                    }}
                  >
                    Subtitles
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#fff", // White text color for subtitles
                      fontWeight: "bold",
                      padding: 1,
                      textAlign: "justify",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontFamily: "Jameel Noori",
                    }}
                  >
                    {currentSubtitle
                      ? currentSubtitle
                      : "NO Subtitle to show for now"}
                  </Typography> */}
                </Box>

                {audioSrc && (
                  <>
                    {/* <AudioPlayer
                      src={audioSrc}
                      ref={audioRef}
                      autoPlay={false}
                      onTimeUpdate={handleTimeUpdate}
                    /> */}
                    <audio
                      controls
                      src={audioSrc}
                      ref={audioRef}
                      onTimeUpdate={handleTimeUpdate}
                      style={{
                        width: "100%",
                        maxWidth: "615px",
                        // Adjust height as needed
                        outline: "none", // Removes the outline when focused
                        margin: "0 auto", // Centers the audio player if it's within a flex container
                      }}
                    />
                  </>
                )}
              </Box>
              {points ? (
                <Box
                  sx={{
                    width: "90%",
                    maxWidth: {
                      xs: "100%",
                      sm: "400px",
                      md: "600px",
                    },
                    margin: "0 auto",
                    minHeight: {
                      xs: "300px",
                      sm: "400px",
                      md: "500px",
                    },
                    maxHeight: "80vh",
                    overflowY: "auto",
                    backgroundColor: "#385f7a",
                    padding: 2,
                    borderRadius: 1,
                    color: "white",
                    marginTop: 2,
                  }}
                >
                  {Object.entries(evaluation).map(([key, value], index) => (
                    <Box key={index} sx={{ marginBottom: 4 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          marginBottom: 2,
                          color: "#FFD700",
                          textTransform: "capitalize",
                        }}
                      >
                        {`${index + 1}. ${key.replace(/_/g, " ")}`}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "1.2rem",
                          fontWeight: "medium",
                          marginBottom: 1,
                          color: "#FFEC8B",
                        }}
                      >
                        Obtained Points:{" "}
                        <span style={{ fontWeight: "bold", color: "#FFFFFF" }}>
                          {value.obtained_points}
                        </span>
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          color: "#FFFFFF",
                          marginBottom: 1,
                          display: "block",
                        }}
                      >
                        Justifications:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1rem",
                          color: "#E0E0E0",
                          textAlign: "justify",
                          lineHeight: 1.8,
                          marginLeft: 2,
                        }}
                      >
                        {value.justification}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "90%",
                    maxWidth: {
                      xs: "100%",
                      sm: "400px",
                      md: "600px",
                    },
                    margin: "0 auto",
                    minHeight: {
                      xs: "300px",
                      sm: "400px",
                      md: "500px",
                    },
                    maxHeight: "80vh",
                    overflowY: "auto",
                    backgroundColor: "#385f7a",
                    padding: 2,
                    borderRadius: 1,
                    color: "white",
                    marginTop: 2,
                  }}
                >
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      color: "#FFD700",
                      marginBottom: 2,
                      textTransform: "uppercase",
                    }}
                  >
                    {transcriptionType}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: 2,
                      fontFamily:
                        transcriptionType === "Urdu Transcript"
                          ? "Jameel Noori"
                          : "Poppins, sans-serif",
                      fontSize:
                        transcriptionType === "Urdu Transcript"
                          ? "1.3rem"
                          : "1rem",
                      color: "#E0E0E0",
                      textAlign: "justify",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: 2,
                        fontFamily:
                          transcriptionType === "Urdu Transcript"
                            ? "Jameel Noori"
                            : "Poppins, sans-serif",
                        fontSize:
                          transcriptionType === "Urdu Transcript"
                            ? "1.3rem"
                            : "1rem",
                        color: "#E0E0E0",
                        textAlign: "justify",
                      }}
                    >
                      {transcriptionType === "Hold Flow" ? (
                        <ul
                          style={{
                            listStyleType: "none",
                            paddingLeft: 0,
                            color: "#E0E0E0",
                          }}
                        >
                          {transcribtionData.map((item, index) => (
                            <li key={index} style={{ marginBottom: "0.5rem" }}>
                              <b style={{ color: "yellow" }}>
                                Hold {index + 1}:
                              </b>{" "}
                              Begins at: {item.start_seconds.toFixed(2)} s{" "}
                              <b style={{ color: "yellow" }}>{"--->"}</b> Ends
                              at: {item.end_seconds.toFixed(2)} s
                            </li>
                          ))}
                        </ul>
                      ) : transcriptionType === "Troubleshoot Steps" ? (
                        <ol style={{ marginLeft: "1.5rem", color: "#E0E0E0" }}>
                          {transcribtionData.split(/\d+\.\s/).map((step, i) =>
                            step ? (
                              <li key={i} style={{ marginBottom: "0.5rem" }}>
                                {step.trim()}
                              </li>
                            ) : null
                          )}
                        </ol>
                      ) : (
                        <span>{transcribtionData}</span>
                      )}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "#1e2a38",
                  color: "white",
                  overflowX: "auto",
                  textAlign: "center",
                  marginTop: 6,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: 2, fontWeight: "bold" }}
                >
                  Audio Details
                </Typography>
                <Table sx={{ minWidth: 300 }} aria-label="audio details table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          color: "white",
                          backgroundColor: "#3b4d65",
                          fontWeight: "bold",
                        }}
                      >
                        Audio Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          backgroundColor: "#3b4d65",
                          fontWeight: "bold",
                        }}
                      >
                        {fetchaudioDetails.name}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        Flow Marks
                      </TableCell>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        {fetchaudioDetails.details?.AudioMetaData ? (
                          fetchaudioDetails.details.AudioMetaData.FlowMarks.toFixed(
                            1
                          )
                        ) : (
                          <span>No data available</span>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        Total Duration Original
                      </TableCell>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        {fetchaudioDetails.details?.AudioMetaData ? (
                          fetchaudioDetails.details.AudioMetaData.TotalDurationOriginal.toFixed(
                            1
                          )
                        ) : (
                          <span>No data available</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        Longer Silent Spots
                      </TableCell>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        {fetchaudioDetails.details?.AudioMetaData ? (
                          fetchaudioDetails.details.AudioMetaData
                            .LongerSilentSpots
                        ) : (
                          <span>No data available</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        Detected Issue
                      </TableCell> */}
                      {/* <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        {fetchaudioDetails.details?.AudioMetaData ? (
                          fetchaudioDetails.details.DetectedIssue
                        ) : (
                          <span>No data available</span>
                        )}
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        Issue Title
                      </TableCell>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        {fetchaudioDetails.details?.AudioMetaData ? (
                          fetchaudioDetails.details.suggestedIssue
                        ) : (
                          <span>No data available</span>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "#1e2a38",
                  color: "white",
                  marginBottom: 2,
                  overflowX: "auto",
                  textAlign: "center",
                  marginTop: 4,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: 4,
                    fontFamily: "Newsreader, serif",
                    fontWeight: "bold",
                  }}
                >
                  Complete Analysis Report
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <GaugeChart
                    id="gauge-chart-overall"
                    nrOfLevels={20}
                    colors={["#C62828", "#FFB300", "#388E3C"]}
                    arcWidth={0.3}
                    style={{ width: "250px", height: "250px" }}
                    formatTextValue={() => {
                      const flowMarks =
                        fetchaudioDetails?.details?.AudioMetaData?.FlowMarks ||
                        0;
                      const totalMarks = totalObtainedMarks || 0;
                      const totalValue = totalMarks + flowMarks;
                      return totalValue.toFixed(1);
                    }}
                    textColor="#fff"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      maxWidth: "250px",
                      marginTop: -10,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "#fff", marginTop: -10 }}
                    >
                      0
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#fff", marginTop: -10 }}
                    >
                      {totalMaxScore}
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ marginTop: -5 }}>
                    Overall Score
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  {Object.entries(evaluation).map(([key, value], index) => {
                    const obtainedPoints = value.obtained_points;
                    const maxScore = metrics[index]?.maxScore || 5;
                    const percentage =
                      obtainedPoints != null && !isNaN(obtainedPoints)
                        ? parseInt(obtainedPoints, 10) / maxScore
                        : 0;
                    const isLastTwo =
                      index >= Object.entries(evaluation).length - 2;
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={index}
                        sx={{
                          justifyContent: isLastTwo ? "center" : "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 2,
                          }}
                        >
                          <GaugeChart
                            id={`gauge-chart-${index}`}
                            nrOfLevels={20}
                            colors={["#C62828", "#FFB300", "#388E3C"]}
                            arcWidth={0.3}
                            percent={percentage}
                            style={{ width: "155px", height: "150px" }}
                            formatTextValue={() => `${obtainedPoints}`}
                            textColor="#fff"
                            animate={true}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              maxWidth: "150px",
                              marginTop: -2,
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ color: "#fff", marginTop: -10 }}
                            >
                              0
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "#fff", marginTop: -10 }}
                            >
                              {maxScore}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body1"
                            sx={{
                              marginTop: -4,
                              textAlign: "center",
                              fontWeight: "bold",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                              width: "100%",
                              maxWidth: "150px",
                              overflowWrap: "break-word",
                            }}
                          >
                            {key
                              .replace(/_/g, " ")
                              .replace(/technical|score/gi, "")
                              .toUpperCase()
                              .trim()}
                            {/* {obtainedPoints} */}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
};

export default ViewDetailsContent;
