import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardContent, Fade } from "@mui/material";
import { CheckCircle, Chat, Lock } from "@mui/icons-material"; 
import { styled } from '@mui/system';

// Styled component for the card
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px', 
  padding: theme.spacing(2), 
  backgroundColor: '#101b23',
  color: 'white',
  textAlign: 'center', 
  height: '50vh',
  transition: 'box-shadow 0.3s', // Optional transition for hover effect
  '&:hover': {
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
}));
const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 100,
  height: 100,
  borderRadius: '50%',
  backgroundColor: '#e3f2fd',
  marginBottom: theme.spacing(2),
}));
const WhyUsSectionComponent = () => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    // Start with fade-in
    setFade(true);

  }, []);

  return (
    <Box sx={{ padding: 2, mb: 4, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h3" sx={{ textAlign: 'center', marginBottom: 2, fontFamily: 'Poppins, sans-serif' ,fontWeight:800}}>
        Why ToneSync
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch"> 
        {/* Card 1 */}
        <Grid item xs={12} sm={6} md={4}>
          <Fade in={fade} timeout={1000}>
            <StyledCard>
           
              <CheckCircle sx={{ fontSize: '80px', color: '#4caf50' }} />
              
              <CardContent> 
                <Typography variant="h5" sx={{  marginY: 1 ,fontFamily: 'Poppins, sans-serif' ,fontWeight:800}}>
                  Accurate Results
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
                  We provide more than 80% accuracy.
                </Typography>
              </CardContent>
            </StyledCard>
          </Fade>
        </Grid>
        {/* Card 2 */}
        <Grid item xs={12} sm={6} md={4}>
          <Fade in={fade} timeout={1000}>
            <StyledCard>
              <Chat sx={{ fontSize: '80px', color: '#2196f3' }} /> 
              <CardContent>
                <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' ,fontWeight:800, marginY: 1 }}>
                  Bubble Chat Typing
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
                  Support and Customizations. ToneSync is highly customizable for particular requirements.
                </Typography>
              </CardContent>
            </StyledCard>
          </Fade>
        </Grid>
        {/* Card 3 */}
        <Grid item xs={12} sm={6} md={4}>
          <Fade in={fade} timeout={1000}>
            <StyledCard>
              <Lock sx={{ fontSize: '80px', color: '#f44336' }} /> 
              <CardContent>
                <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' ,fontWeight:800, marginY: 1 }}>
                  Secure Systems
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
                  We provide full security as well as options for on-premises deployment.
                </Typography>
              </CardContent>
            </StyledCard>
          </Fade>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyUsSectionComponent;
